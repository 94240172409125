<template>
<div class="kt-grid kt-grid--hor kt-grid--root ">
    <router-view id="landing-root" />
</div>
</template>

<script>
export default {
    name: 'TheLandingLayout',
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
div#landing-root {
  background-color: white;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden !important; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}
</style>
